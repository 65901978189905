import React, { useState, useEffect } from 'react';
import './artist-stripe-return.scss';
import logo from '../old/assets/images/logo/logo-morphing-white.gif';

const ArtistStripeReturn = () => {
	return (
		<div className="artistStripeReturn">
			<img alt="quan" className="artistStripeReturn_logo" src={logo} />
			<div className="artistStripeReturn_wrapper">
				<h3>Stripe Onboarding ended!</h3>
				<p className="t3">
					Please open QUAN mobile app for artists and check if your Stripe onboarding finished successfully. If you did
					not finish all the steps in onboarding, please do so again by "Complete" button in the native app.
				</p>
				{/* For deeplinking the domain needs to be different than the one on web */}
				<a href="https://dev.quan.global/artist-stripe-return">
					<div className="button">
						<p>OPEN APP</p>
					</div>
				</a>
			</div>
		</div>
	);
};

export default ArtistStripeReturn;
